.facetwp-type-search {
  .facetwp-icon {
    left: 1rem;
    right: auto;
    opacity: 1;

    &:before {
      width: .875rem;
      background: url('~/assets/icons/search-primary-light.svg') no-repeat center center;
      background-size: contain;
      
      body.page-template-archive-news & {
        background: url('~/assets/icons/search-white.svg') no-repeat center center;
        background-size: contain;
      }

      body.tax-case_category & {
        background: url('~/assets/icons/search.svg') no-repeat center center;
        background-size: contain;
      }
    }
  }

  .facetwp-search {
    background: transparent;
    border: 1px solid $c-primary-light;
    border-radius: 10rem;
    font-size: $font--16;
    line-height: 2.215;
    color: $c-primary-light;
    padding: 0 .5rem 0 2.5rem;

    body.page-template-archive-news & {
      border-color: $c-primary-extra-light;
      color: $c-primary-extra-light;
    }

    body.tax-case_category & {
      border-color: $c-primary;
      color: $c-primary;
    }

    &:focus-visible {
      outline: none;
    }

    &::placeholder {
      color: $c-primary-light;
      opacity: .5;

      body.page-template-archive-news & {
        color: $c-primary-extra-light;
      }

      body.tax-case_category & {
        color: $c-primary;
      }
    }
  }
}