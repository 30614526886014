.footer--main {
  border-bottom: 1rem solid $c-primary-light;

  @media #{$breakpoint-md-mx} {
    align-items: start;
  }

  .footer__logo {
    width: 10rem;

    @media #{$breakpoint-md-mx} {
      margin: 0 auto;
    }
  }

  .footer__address,
  .footer__info {
    font-size: $font--14;
    line-height: 1.55;

    @media #{$breakpoint-md-mx} {
      text-align: center;
    }

    p {
      margin-bottom: .35rem;
    }

    a {
      color: #FFF;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    strong {
      color: $c-primary-light;
      font-weight: 600;
    }
  }
}