.block--search-form {
  &.open {
    input[type="text"] {
      width: 12rem;
      padding: .5rem .75rem .5rem 2rem;
      opacity: 1;
      transition: width .5s ease-out, padding .5s ease-out, opacity .5s .25s ease-out;
    }

    .block__icon {
      left: .5rem;
    }
  }

  input[type="text"] {
    width: 0;
    font-size: $font--14;
    border: 1px solid $c-primary;
    border-radius: 4rem;
    background: transparent;
    opacity: 0;
    transition: width .5s .25s ease-out, padding .5s .5s ease-out, opacity .5s ease-out;

    &:focus-visible {
      outline: none;
    }

    &::placeholder {
      color: $c-primary;
    }
  }

  .block__icon {
    left: -.75rem;
    background: url('~/assets/icons/search.svg') no-repeat center center;
    background-size: contain;
    transition: all .5s ease-in-out;
  }

  .header--main.open & {
    .block__icon {
      background: url('~/assets/icons/search-white.svg') no-repeat center center;
      background-size: contain;
    }

    &.open input[type="text"] {
      border-color: #FFF;
      color: #FFF;

      &::placeholder {
        color: #FFF;
      }
    }
  }
}