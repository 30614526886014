.post.type-post {
  // INSIGHTS & PUBLICATIONS
  &.category-insights,&.category-publications {
    .post__header {
      background: $c-primary;
    }

    .post__content {
      border-right: 1px solid $c-primary-light;

      &:after {
        background: linear-gradient(0, #{$c-primary-extra-light} 0, rgba(221,235,242,0) 100%);
      }
    }

    .post__body {
      max-width: 40rem;
    }
  }
  
  // EVENTS & NEWS
  &.category-events,&.category-news {
    .post__header {
      background: $c-primary-dark;
    }

    .post__content-wrapper {
      @media (min-width: 52rem) and (orientation: portrait) {
        max-height: 50vh;
      }
    }

    .post__content:after {
      background: linear-gradient(0, #FFF 0, rgba(255,255,255,0) 100%);
    }

    .post__body-wrapper {
      &::-webkit-scrollbar {
        background: $c-primary-light;
        outline: none;
      }

      &::-webkit-scrollbar-thumb {
        background: $c-primary url('~/assets/icons/arrow-primary-light-down.svg') no-repeat center calc(100% - .5rem);
        background-size: 75%;
      }
    }

    .post__body {
      max-width: 57.5rem;
    }
  }

  // POST
  .post__title-wrapper {
    border-bottom: 1px solid $c-primary-light;
  }

  .post__title {
    @include linear-responsive(2.625,3.125);
  }

  .post__column-media {
    border-right: 1px solid $c-primary-light;

    @media #{$breakpoint-sm-mx} {
      border-right: none;
      border-bottom: 1px solid $c-primary-light;
    }
  }

  .post__content-wrapper {
    @media (min-width: 52rem) and (max-height: 54rem) {
      min-height: 48rem;
    }
  }

  .post__content:after {
    content: '';
    @extend .cover;
    top: 75%;
    right: 1rem;
    pointer-events: none;

    @media #{$breakpoint-md-mx} {
      content: none;
    }
  }

  .post__body-wrapper {
    overflow-y: scroll;

    @media #{$breakpoint-md-mx} {
      overflow: unset;
    }

    &::-webkit-scrollbar {
      outline: 1px solid $c-primary-light;
    }
  
    &::-webkit-scrollbar-thumb {
      background: $c-primary-light url('~/assets/icons/arrow-primary-extra-light-down.svg') no-repeat center calc(100% - .5rem);
      background-size: 75%;
    }
  }

  .post__author,
  .post__date {
    font-size: $font--20;
    padding-bottom: .25rem;
    margin-bottom: .75rem;
    border-bottom: 1px solid $c-primary-light;
  }
}