/* COMMON
  ----------------------------- */
.section--about-specialists,
.section--about-values,
.section--about-network {
  height: calc(100vh - #{$header-height});

  @media (min-width: 140rem) {
    max-height: 75vh;
  }

  @media (min-width: 52rem) and (max-width: 80rem) {
    height: calc(100vh - #{$header-height} + 8rem);
  }

  @media (min-width: 52rem) and (orientation: portrait) {
    max-height: 50vh;
  }
}

.section--about-membership,
.section--about-certifications {
  @media (min-width: 52rem) and (max-width: 90rem) {
    padding-top: 3rem;
  }

  .section__slide {
    display: flex !important;
  }

  .section__content-wrapper {
    aspect-ratio: 1/1;
    max-height: 35rem;

    @media (max-width: 90rem) {
      max-height: 28rem;
    }
  }

  .section__content {
    max-width: 30rem;
    opacity: 0;
    pointer-events: none;
    transition: .25s ease-in-out;
    
    &.show {
      opacity: 1;
      pointer-events: all;
      transition: .25s ease-in-out;
    }
  }

  .section__abstract {
    font-size: $font--14;
  }

  .section__info,.section__logo-wrapper {
    transition: .25s ease-in-out;
    
    &.hidden {
      opacity: 0;
      transition: .25s ease-in-out;
    }
  }
}

/* BOARD
  ----------------------------- */
.section--about-board {
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 3rem;
    border-left: 1px solid $c-primary-light;
    z-index: 99;

    @media #{$breakpoint-md-mx} {
      content: none;
    }
  }

  .section__title {
    @media #{$breakpoint-md-mx} {
      text-align: left;
    }
  }

  .section__slider {
    border-bottom: 1px solid $c-primary-light;
    @include linear-responsive(4,7.5,'padding-left');

    @media #{$breakpoint-md-mx} {
      padding-left: 0;
    }
  }

  .section__slide {
    width: calc(50% / 4); // number of slides - active one
    height: 32vw;
    transition: all .5s .5s ease-in-out;
    
    @media (max-width: 90rem) {
      height: 40vw;
      // transition: all .5s ease-in-out;
    }

    @media #{$breakpoint-md-mx} {
      height: 8rem;
      width: 100%;
    }

    @media #{$breakpoint-sm-mx} {
      height: 6.5rem;
    }

    // SLIDE ACTIVE
    &.active {
      width: 50%;

      @media #{$breakpoint-md-mx} {
        height: 50vw;
        width: 100%;
      }

      @media #{$breakpoint-sm-mx} {
        height: 90vw;
      }

      .section__slide-image {
        transform: translateX(0);
        
        &:after {
          opacity: 0;
        }
      }

      .section__slide-preview-content {
        opacity: 0;
        pointer-events: none;
        transition: opacity .5s ease-in-out;
      }

      .section__slide-bio {
        height: auto;
        opacity: 1;
      }
    }
  }

  .section__slide-container {
    width: 46.5vw;

    @media #{$breakpoint-md-mx} {
      width: 100vw;
    }
  }

  .section__slide-image {
    transform: translateX(-16.5%);
    transition: transform .5s .5s ease-in-out;
    
    @media #{$breakpoint-md-mx} {
      transform: translateX(0);

      img {
        object-position: center 20%;
      }
    }

    &:after {
      content: '';
      @extend .cover;
      opacity: 1;
      background: linear-gradient(180deg, rgba(0, 20, 49, 0) 50%, #001431 100%);
      transition: opacity .5s .5s ease-in-out;
      
      @media #{$breakpoint-md-mx} {
        opacity: .5;
        background: linear-gradient(180deg, rgba(0,20,49,0) 0%, rgba(0,20,49,.75) 100%);
        transition: opacity .5s ease-in-out;
      }
    }
  }
  
  .section__slide-title {
    @include linear-responsive(2.25,3);
    font-weight: 200;

    @media #{$breakpoint-sm-mx} {
      font-size: $font--24;
    }
  }

  .section__slide-position {
    @media #{$breakpoint-sm-mx} {
      font-size: $font--14;
      padding-top: .25rem;
    }
  }
  
  .section__slide-bio {
    font-size: $font--14;
    line-height: 1.5;
    @include linear-responsive(.25,4,'padding-left');
    margin-top: 2.5rem;
    
    @media (max-width: 90rem) {
      padding-left: 0;
      padding-right: 1rem;
      overflow-y: scroll;
      flex: 1 0 0;
    }
    
    @media #{$breakpoint-md-mx} {
      margin: 1rem 0;
      padding-right: 2rem;
      overflow-y: auto;
      height: 0;
      opacity: 0;
    }
  }
  
  .section__slide-preview-content {
    opacity: 1;
    transition: opacity .5s 1s ease-in-out;
  }

  .section__slide-preview-position {
    font-size: .75rem;
  }

  .section__arrows {
    transform: translateY(50%);
  }

  .section__arrow {
    background: #FFF url('~/assets/icons/arrow-circle-primary-light.svg') no-repeat center center;
    background-size: contain;
  
    &:hover {
      background: url('~/assets/icons/arrow-circle-primary-full.svg') no-repeat center center;
      background-size: contain;
    }

    &.section__arrow-prev {
      transform: rotate(180deg);
    }
  }
}

/* SPECIALISTS
  ----------------------------- */
.section--about-specialists {
  .section__container {
    z-index: 3;

    &:before {
      content: '';
      @extend .cover;
      background: radial-gradient(50% 50% at 50% 50%, rgba(0,20,49,.4) 0%, rgba(0,20,49,0) 100%);
    }
  }

  .section__abstract {
    max-width: 32.5rem;

    @media (min-width: 140rem) {
      max-width: 46rem;
    }
  }

  .section__gallery-wrapper {
    height: 65%;
    top: 37.5%;
    backface-visibility: hidden;
  }

  .section__gallery-item {
    width: calc(100vh/4);
    height: calc(100vh/4);
    transform-origin: center center;
    opacity: 0;

    @media #{$breakpoint-xl-mx} {
      width: calc(100vh/5);
      height: calc(100vh/5);
    }

    figure img {
      overflow: hidden;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }
}

/* VALUES
  ----------------------------- */
.section--about-values {
  border-top: 1px solid $c-primary-light;

  .section__value {
    transition: all .3s ease-in-out;
    opacity: .9;

    @media #{$breakpoint-md-mx} {
      flex-wrap: wrap;
    }

    &.open {
      padding-top: .75rem;

      .section__value-title {
        font-weight: 600;
      }

      .section__arrow {
        transform: rotate(90deg);
        background: url('~/assets/icons/arrow-circle-white-full.svg') no-repeat center center;
        background-size: contain;
      }
    }
  }

  .section__value-title {
    font-size: $font--20;
    font-weight: 200;
    line-height: 1.3;

    @media #{$breakpoint-md-mx} {
      font-size: $font--16;
      font-weight: 300;
      width: 75vw;
    }
  }

  .section__value-body {
    display: none;
    line-height: 1.5;
    max-width: 51.25rem;

    @media (max-width: 90rem) {
      line-height: 1.35;
      font-size: $font--16;
    }

    @media #{$breakpoint-md-mx} {
      order: 2;
      font-size: $font--14;
    }
  }

  .section__arrow {
    transform: rotate(-90deg);
    transition: all .3s ease-in-out;
  }

  $colors: ($c-primary-light,$c-primary,$c-primary-dark);
  
  @for $i from 1 through length($colors) {
    .section__value:nth-child(3n+#{$i}) {
      background: nth($colors, $i);
    }
  }
}

/* NETWORK
  ----------------------------- */
.section--about-network {
  @media #{$breakpoint-md-mx} {
    height: auto;
  }

  .section__description-wrapper {
    max-width: 51.25rem;
  }

  .section__content-container,
  .section__image-wrapper,
  .section__video-wrapper {
    border-top: 1px solid $c-primary-light;
    border-bottom: 1px solid $c-primary-light;
    margin: 3.5rem 0;
    height: calc(100% - 7rem);

    @media #{$breakpoint-md-mx} {
      border-top: 0;
      border-bottom: 0;
      margin: 0;
      height: auto;
    }
  }

  .section__image-container {
    border-left: 1px solid $c-primary-light;

    @media #{$breakpoint-md-mx} {
      border-left: none;
    }
  }
  
  .section__logo-wrapper {
    width: 30rem;

    @media (max-width: 90rem) {
      width: 24rem;
    }

    @media #{$breakpoint-md-mx} {
      width: 100%;
      max-width: 18rem;
    }
  }

  .section__description {
    font-size: $font--24;

    @media (max-width: 90rem) {
      font-size: $font--18;
    }
  }

  .section__title {
    @media #{$breakpoint-md-mx} {
      text-align: left;
    }
  }

  .section__video-wrapper {
    @media #{$breakpoint-md-mx} {
      aspect-ratio: 16/9;
    }
  }
}

/* MEMBERSHIP
  ----------------------------- */
.section--about-membership {
  border-top: 1px solid $c-primary-light;

  .section__logo-wrapper {
    width: 36rem;
    height: 10rem;
    max-width: 100vw;

    @media (max-width: 90rem) {
      height: 8rem;
    }
  }
}

/* CERTIFICATIONS
  ----------------------------- */
.section--about-certifications {
  .section__area {
    font-size: $font--30;
    font-weight: 200;

    @media #{$breakpoint-md-mx} {
      font-size: $font--24;
    } 
  }

  .section__code {
    font-size: $font--20;
    font-weight: 200;

    @media #{$breakpoint-md-mx} {
      font-size: $font--16;
    }
  }

  .section__logo-wrapper {
    width: 24rem;
    height: 8rem;

    @media (max-width: 90rem) {
      height: 6rem;
    }

    @media #{$breakpoint-md-mx} {
      width: 18rem;
    }
  }

  .section__info {
    max-width: 38rem;
  }
}

/* REPORT
  ----------------------------- */
.section--about-report {
  .section__title {
    @media #{$breakpoint-sm-mx} {
      @include linear-responsive(3.25, 5.95);
    }
  }

  .section__slider-wrapper {
    @media (min-width: 140rem) {
      width: 50%;
    }

    @media (min-width: 52rem) and (max-width: 105rem) {
      width: 66%;
    }
  }

  .section__slider {
    padding: 0 4rem;

    @media (max-width: 90rem) {
      padding: 0 3.5rem;
    }
  }

  .section__link {
    min-width: auto;

    @media (max-width: 90rem) {
      padding: .75rem 1rem;
    }

    &:after {
      flex-shrink: 0;
      margin-left: 2vw;
    }
  }

  .section__report-title {
    @media (max-width: 105rem) {
      font-size: $font--14;
    }

    @media #{$breakpoint-sm-mx} {
      font-size: 0;

      .section__report-year {
        font-size: $font--14;
      }
    }
  }
  
  .section__report-year {
    font-weight: 600;
  }
}