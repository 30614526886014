.facetwp-type-pager {
  .facetwp-pager {
    display: flex;
    align-items: center;
  }

  .facetwp-page {
    height: 2.25rem;
    margin: 0;
  }

  .facetwp-page.next,
  .facetwp-page.prev {
    width: 2.25rem;
    border-radius: 10rem;
    border: 1px solid $c-primary-light;
    font-size: 0;
    background: url('~/assets/icons/arrow-primary-light.svg') no-repeat .9rem center;
    background-size: 20%;

    &:hover {
      background: $c-primary-light url('~/assets/icons/arrow-white.svg') no-repeat .9rem center;
      background-size: 20%;
    }
  }

  .facetwp-page.next {
    margin-left: .75rem;
  }

  .facetwp-page.prev {
    margin-right: .75rem;
    transform: rotate(180deg);
  }

  .facetwp-page:not(.next):not(.prev) {
    color: $c-primary-light;
    font-size: $font--14;
    padding: .5rem .35rem;
    border-top: 1px solid $c-primary-light;
    border-bottom: 1px solid $c-primary-light;

    &.first {
      border-top-left-radius: 10rem;
      border-bottom-left-radius: 10rem;
      border-left: 1px solid $c-primary-light;
      padding-left: .75rem;
    }

    &.last {
      border-top-right-radius: 10rem;
      border-bottom-right-radius: 10rem;
      border-right: 1px solid $c-primary-light;
      padding-right: .75rem;
    }

    &.active {
      background: $c-primary-light;
      color: #FFF;
      font-weight: 400;
      padding-left: 1.125rem;
      padding-right: 1.125rem;
      border-radius: 10rem;
      position: relative;

      &:before,&:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        border-top: 1px solid $c-primary-light;
      }

      &:before {
        top: -1px;
      }

      &:after {
        bottom: -1px;
      }
    }

    &.first.active:before,&.first.active:after {
      left: 50%;
    }

    &.last.active:before,&.last.active:after {
      right: 50%;
    }

    &:hover {
      text-decoration: none;
    }
  }
}