.section--archive-case-content {
  .section__body {
    @include linear-responsive(1.125, 1.5);
  }

  .section__container {
    border-top: 1px solid $c-primary;
    border-bottom: 1px solid $c-primary;
    height: 50vh;

    @media (min-width: 52rem) and (max-height: 54rem) {
      min-height: 30rem;
    }

    @media (min-width: 52rem) and (orientation: portrait) {
      max-height: 40vh;
    }

    @media #{$breakpoint-md-mx} {
      height: auto;
    }
  }

  .section__wrapper {
    border-right: 1px solid $c-primary;

    @media #{$breakpoint-md-mx} {
      border-right: 0;
      border-bottom: 1px solid $c-primary;
    }
  }

  .section__body {
    max-width: 32rem;
  }

  .section__search {
    max-width: 24rem;

    .facetwp-type-search,
    .facetwp-input-wrap,
    .facetwp-search {
      width: 100%;
    }
  }

  .section__archive-wrapper {
    @media #{$breakpoint-md-mx} {
      height: 80vh;
    }

    &:after {
      content: '';
      @extend .cover;
      top: 75%;
      left: 1rem;
      background: linear-gradient(0, #{$c-primary-extra-light} 0, rgba($c-primary-extra-light, 0) 100%);
      pointer-events: none;

      @media #{$breakpoint-md-mx} {
        top: 85%;
      }
    }
  }

  .section__archive {
    overflow-y: scroll;
    direction: rtl;

    // @media #{$breakpoint-md-mx} {
    //   direction: ltr;
    // }

    &::-webkit-scrollbar {
      outline: 1px solid $c-primary;
    }

    &::-webkit-scrollbar-thumb {
      background: $c-primary url('~/assets/icons/arrow-primary-extra-light-down.svg') no-repeat center calc(100% - .25rem);
      background-size: 75%;
    }
  }

  .section__list {
    direction: ltr;
    padding-bottom: 7.5rem;

    @media #{$breakpoint-md-mx} {
      padding-bottom: 5rem;
    }
  }

  .section__item {
    border-bottom: 1px solid $c-primary;

    &:not(:first-child) {
      padding-top: 1.5rem;
    }
  }
}