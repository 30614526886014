html {
  font-size: 16px; // 1rem
}

body {
  font-size: $font--18;
  font-family: $sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: geometricPrecision;
  color: $c-primary-dark;

  // &.open {
  //   overflow: hidden;
  // }
}

* {
  box-sizing: border-box;
}

img, video {
  vertical-align: top;

  &.cover-image {
    object-fit: cover;
    object-position: center;
    font-family: 'object-fit: cover; object-position: center;';
    height: 100%;
    width: 100%;
  }

  &.contain-image {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }

  &.lazy {
    opacity: .1;
    transition: opacity 0.6s 0.05s ease;
    image-rendering: pixelated;
    
    &.loaded {
      opacity: 1;
      image-rendering: auto;
    }

    &--gif {
      opacity: 1;
    }
  }
}

figure {
  margin: 0;
  height: 100%;
}

// FIX LAZY ON SAFARI
@media not all and (min-resolution:.001dpcm) { @supports (-webkit-appearance:none) {
  img.lazy {
    opacity: 1;
  }
}}

.fluid {
  width: 100%;
  height: auto;
}

.fluid-y {
  height: 100%;
}

.max-width-5 {
  max-width: 80rem; // 1280px
}

.cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.absolute-center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.absolute-center-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.iframe-center {
  @extend .absolute-center;
  width: 100vw;
  height: 56.25vw;
  min-height: 100vh;
  min-width: 177.77vh;
}

.content-area {
  margin-top: $header-height;

  @media #{$breakpoint-md-mx} {
    margin-top: $header-height-md;
  }

  &.open {
    position: relative;

    &:after {
      content: '';
      @extend .cover;
      position: fixed;
      background: rgba(255,255,255,.1);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      z-index: 9;
    }
  }
}

.pointer {
  cursor: pointer;
}

.grecaptcha-badge {
  display: none !important;
}

.facetwp-facet {
  margin-bottom: 0 !important;
}