/* COMMON
  ----------------------------- */
.preview--post,
.preview--service,
.preview--case {
  >a:hover {
    text-decoration: none;
  }
}

/* SERVICE
  ----------------------------- */
.preview--service {
  .preview__link {
    box-shadow: inset 0 0 0 1px #FFF;
    transition: all .3s ease-in-out;

    .section__list-secondary & {
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
    }
    
    &:hover {
      background: #FFF;

      .preview__icon-alt {
        display: block;
      }
    
      .preview__icon-main {
        display: none;
      }

      // PRIMARY
      .section__list-primary & {
        .preview__icon-wrapper {
          border-color: $c-primary;
        }

        .preview__title {
          color: $c-primary;
        }

        .preview__arrow {
          background: url('~/assets/icons/arrow-circle-primary-full.svg') no-repeat center center;
          background-size: contain;
        }
      }

      // SECONDARY
      .section__list-secondary & {
        .preview__icon-wrapper {
          border-color: $c-primary-mid;
        }

        .preview__title {
          color: $c-primary-mid;
        }

        .preview__arrow {
          background: url('~/assets/icons/arrow-circle-primary-mid-full.svg') no-repeat center center;
          background-size: contain;
        }
      }
    }
  }

  .preview__content {
    max-width: 24rem;

    @media (min-width: 140rem) {
      max-width: none;
    }
  }

  .preview__icon-wrapper {
    border-right: 1px solid #FFF;
    flex-shrink: 0;
  }

  .preview__icon {
    width: 2.75rem;
    height: 2.5rem;
  }

  .preview__icon-alt {
    display: none;
  }

  .preview__title {
    @include linear-responsive(.75,1.125);
    font-weight: 400;
    
    .section__list-primary & {
      @include linear-responsive(.75,1.5);
    }
  }
}

/* POST
  ----------------------------- */
.preview--post {
  >a:hover .preview__arrow {
    background: url('~/assets/icons/arrow-circle-white-full.svg') no-repeat center center;
    background-size: contain;
  }
}

/* POST NEWS + CASE
  ----------------------------- */
.preview--post-news,
.preview--case {
  .preview__info {
    font-size: $font--14;
    font-weight: 600;
  }

  .preview__title {
    font-size: $font--24;
    font-weight: 200;
  }

  .preview__arrow {
    flex-shrink: 0;
  }
}

/* POST INSIGHTS
  ----------------------------- */
.preview--post-insights {
  .preview__date {
    font-size: $font--14;
    font-weight: 600;
  }

  .preview__title {
    font-size: $font--30;
    font-weight: 400;
  }

  .preview__abstract {
    body.home & {
      @media #{$breakpoint-sm-mx} {
        display: none;
      }
    }
  }

  .preview__terms {
    row-gap: .5rem;
  }

  .preview__term {
    font-size: $font--16;
    text-transform: capitalize;
    pointer-events: none;

    &.preview__term-parent {
      order: -1;
    }
  }

  &:hover {
    background: $c-primary;

    .preview__title,
    .preview__abstract {
      color: #FFF;
    }

    .preview__term {
      color: $c-primary-extra-light;
      border-color: $c-primary-extra-light;

      &.bg--primary {
        background: $c-primary-light;
        border-color: $c-primary-light;
        color: $c-primary;
      }
    }
  }
}

/* CASE
  ----------------------------- */
.preview--case {
  >a:hover .preview__arrow {
    background: url('~/assets/icons/arrow-circle-primary-full.svg') no-repeat center center;
    background-size: contain;
  }
}

/* SEARCH
  ----------------------------- */
.preview--search {
  .preview__link:hover {
    text-decoration: none;
    background: $c-primary;
    color: $c-primary-extra-light;

    .preview__category {
      background-color: $c-primary-extra-light;
      color: $c-primary;
    }
  }

  .preview__category {
    display: inline-block;

    &:hover {
      background-color: $c-primary-extra-light;
      color: $c-primary;
    }
  }
  
  .preview__title {
    font-size: $font--20;
    font-weight: 600;
  }

  .preview__body {
    font-size: $font--18;
  }
}