/* SERVICES HERO + ABOUT HERO + CONTACTS HERO + CAREERS HERO
  ----------------------------- */
.section--services-hero,
.section--about-hero,
.section--contacts-hero,
.section--careers-hero {
  .section__content {
    @media #{$breakpoint-md-mx} {
      flex-direction: column;
    }
  }

  .section__body {
    @include linear-responsive(1.35, 2.625);
    line-height: 1;

    @media #{$breakpoint-md-mx} {
      text-align: left;
    }
  }

  .section__image-wrapper {
    height: calc(100vh - #{$header-height});

    @media #{$breakpoint-md-mx} {
      height: calc(65vh - #{$header-height-md});
    }

    @media #{$breakpoint-sm-mx} {
      height: calc(85vh - #{$header-height-md});
    }
        
    @media (min-width: 52rem) and (orientation: portrait) {
      height: 35vh;
    }

    &:after {
      content: '';
      @extend .cover;
      background: linear-gradient(180deg, rgba(0, 20, 49, 0) 35%, #001431 100%);
    }
  }
}

/* HOME SERVICES + SERVICES LIST
  ----------------------------- */
.section--home-services,
.section--services-list {
  min-height: calc(100vh - #{$header-height});

  @media (min-width: 140rem) {
    min-height: 75vh;
  }

  @media #{$breakpoint-md-mx}, (min-width: 52rem) and (orientation: portrait) {
    min-height: auto;
  }

  .section__subtitle {
    @include linear-responsive(1.5, 2.5);
    font-weight: 200;
  }

  .section__grid {
    display: grid;
    gap: 2.5rem;
  }

  .section__list-primary {
    margin-top: auto;
  }

  .section__list-primary .section__grid {
    grid-template-columns: 1fr 1.5fr 1.5fr;
    align-items: center;

    @media (max-width: 64rem) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media #{$breakpoint-sm-mx} {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 2rem;
    }

    .section__service {
      height: 7rem;

      @media #{$breakpoint-md-mx} {
        height: 5.5rem;
      }

      &:last-child {
        @media (max-width: 64rem) {
          grid-column: 2/3;
        }
      }
    }
  }

  .section__list-secondary .section__grid {
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: 64rem) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media #{$breakpoint-sm-mx} {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
  }
}

/* POST + SERVICE + CASE
  ----------------------------- */
.service.type-service,
.post.type-post,
.case.type-case {
  .service__related-topics-title,
  .post__related-topics-title,
  .case__related-topics-title {
    @media #{$breakpoint-sm-mx} {
      @include linear-responsive(3.25, 5.95);
    }
  }

  .service__related-topics,
  .post__related-topics,
  .case__related-topics {
    @media #{$breakpoint-md-mx} {
      justify-content: start;
    }
  }

  .service__related-topic,
  .post__related-topic,
  .case__related-topic {
    margin: .5rem 0 .5rem 1.5rem;

    @media #{$breakpoint-md-mx} {
      margin: .5rem 1.5rem .5rem 0;
    }

    &:hover {
      color: $c-primary-dark;

      &:after {
        background: url('~/assets/icons/arrow-primary-dark.svg') no-repeat center center;
        background-size: contain;
      }
    }
  }
}

/* COMMON CONTACTS + CONTACTS LIST DROPDOWN
  ----------------------------- */
.section--common-contacts,
.section--contacts-list {
  .section__links {
    width: 32rem;

    @media #{$breakpoint-md-mx} {
      width: 28rem;
    }
  }

  .section__cta {
    @media #{$breakpoint-sm-mx} {
      font-size: $font--14;
      padding: 1rem;

      &:after {
        margin-left: 0;
      }
    }

    &.active:after {
      transform: rotate(90deg);
    }
  }

  .section__dropdown {
    border-bottom-right-radius: 2rem;
    border-bottom-left-radius: 2rem;
    transform: translateY(-1.75rem);
  }

  .section__link,
  .section__privacy-confirmation {
    font-size: $font--14;
    padding: .375rem .35rem;

    &:hover {
      text-decoration: none;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $c-primary-light;
    }
  }
  
  .section__link {
    &:after {
      content: '';
      @extend .absolute-center-y;
      right: .35rem;
      height: .95rem;
      width: .5rem;
      background: url('~/assets/icons/arrow-primary-light.svg') no-repeat center center;
      background-size: contain;
    }

    &.hidden {
      pointer-events: none;
      color: rgba($c-primary,.3);
    }
  }

  .section__privacy-confirmation {
    padding-right: 2rem;

    a {
      color: $c-primary;
      text-decoration: underline;
    }

    span {
      height: 1.125rem;
      width: 1.125rem;
      border-radius: 2rem;
      border: 1px solid $c-primary-light;
    }

    &.checked span {
      background: $c-primary-light url('~/assets/icons/checked.svg') no-repeat center center;
      background-size: 50%;
    }
  }
}

/* COMMON CONTACTS
  ----------------------------- */
.section--common-contacts {
  height: calc(100vh - #{$header-height} - 10rem);

  // body.tax-service_category &,
  body.single-post:has(* > .category-insights) &,
  body.single-service & {
    height: 25rem;
  }

  body.page-template-contacts & {
    height: 40vh;

    @media (min-width: 52rem) and (orientation: portrait) {
      height: auto;
    }
  }
  
  @media (min-width: 52rem) and (max-width: 80rem) {
    height: calc(100vh - #{$header-height} - 4rem);
  }

  @media (min-width: 52rem) and (orientation: portrait) {
    height: auto;
  }

  @media #{$breakpoint-md-mx} {
    height: calc(65vh - #{$header-height-md});
  }

  .section__video-wrapper {
    @media #{$breakpoint-md-mx} {
      height: 100%;
    }
  }

  .section__video {
    @media #{$breakpoint-md-mx} {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 200vw;
    }
  }

  .section__title {
    @media #{$breakpoint-sm-mx} {
      @include linear-responsive(3.25, 5.95);
    }
  }

  .section__content-wrapper {
    transition: all .4s ease-in-out;

    &.active {
      padding-bottom: 2.25rem;
    }
  }

  .section__dropdown {
    display: none;
  }
}

/* COMMON LOADER
  ----------------------------- */
.section--common-loader {
  z-index: 9999;
  position: fixed;

  .section__video {
    width: 18.75rem;
    mix-blend-mode: screen;
  }
}

/* PAGE 404
  ----------------------------- */
body.error404 {
  .page-404__wrapper {
    top: 25%;
    transition: top .4s ease-in-out;

    @media #{$breakpoint-sm-mx} {
      width: 100%;
      top: 20%;
    }

    @media (min-width: 52rem) and (max-width: 90rem) {
      width: 100%;
      top: 20%;
    }
  }

  .page-404__subtitle {
    font-size: $font--30;
    font-weight: 300;

    @media #{$breakpoint-md-mx} {
      font-size: $font--24;
    }
  }

  &:has(.section--common-contacts.active) {
    .page-404__wrapper {
      top: 17%;

      @media #{$breakpoint-md-mx} {
        top: 7.5%;
      }
    }
  }
}

/* PAGE SEARCH
  ----------------------------- */
body.search {
  .page__container {
    max-width: 105rem;
  }

  .page__body {
    font-size: $font--18;
  }

  .page__item:not(:last-child) {
    border-bottom: 1px solid $c-primary-light;
  }
}