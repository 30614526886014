/* COMMON
   ----------------------------- */
.slick-slider.flex {
  .slick-list, 
  .slick-track {
    height: 100%;
  }
  
  .slick-track {
    display: flex;

    .slick-slide {
      height: auto;

      > div,
      > div > * {
        height: 100%;
      }

      > div > *.flex {
        display: flex !important;
      }
    }
  }
}

/* SLIDER ARROW
   ----------------------------- */
.slick-slider .slick-arrow {
  position: absolute;
  display: flex;
  align-items: center;
  appearance: none;
  cursor: pointer;
  border: 1px solid $c-primary;
  border-radius: 10rem;
  background: #FFF url('~/assets/icons/arrow-primary.svg') no-repeat 52.5% center;
  background-size: 22.5%;
  padding: 3.5rem 3.5rem 0 0;
  font-size: 0;
  z-index: 9;

  @media #{$breakpoint-md-mx} {
    padding: 2.75rem 2.75rem 0 0;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background: $c-primary url('~/assets/icons/arrow-white.svg') no-repeat 52.5% center;
    background-size: 25%;
  }

  &.slick-prev {
    left: 0;
    transform: rotate(180deg);
  }

  &.slick-next {
    right: 0;
  }

  // ABOUT BOARDS
  .section--about-board & {
    bottom: 0;
    transform: translateY(50%);
    border-color: $c-primary-light;
    background: #FFF url('~/assets/icons/arrow-primary-light.svg') no-repeat 52.5% center;
    background-size: 22.5%;

    &:hover {
      background: $c-primary-light url('~/assets/icons/arrow-white.svg') no-repeat 52.5% center;
      background-size: 25%;
    }

    &.slick-prev {
      left: auto;
      right: 7.5rem;
      transform: translateY(50%) rotate(180deg);
    }

    &.slick-next {
      right: 2.5rem;
    }
  }

  // ABOUT MEMBERSHIP + CERTIFICATIONS
  .section--about-membership &,
  .section--about-certifications & {
    bottom: 0;

    &.slick-prev {
      left: 2.5rem;

      @media #{$breakpoint-md-mx} {
        left: 2rem;
      }
    }

    &.slick-next {
      right: 2.5rem;

      @media #{$breakpoint-md-mx} {
        right: 2rem;
      }
    }
  }

  .section--about-membership & {
    border-color: $c-primary-light;
    background: #FFF url('~/assets/icons/arrow-primary-light.svg') no-repeat 52.5% center;
    background-size: 22.5%;
  
    &:hover {
      background: $c-primary-light url('~/assets/icons/arrow-white.svg') no-repeat 52.5% center;
      background-size: 25%;
    }
  }

  .section--about-certifications & {
    border-color: #FFF;
    background: transparent url('~/assets/icons/arrow-white.svg') no-repeat 52.5% center;
    background-size: 22.5%;
  
    &:hover {
      background: #FFF url('~/assets/icons/arrow-primary-dark.svg') no-repeat 52.5% center;
      background-size: 25%;
    }
  }

  // ABOUT REPORT
  .section--about-report & {
    top: 0;
    background: $c-primary-extra-light url('~/assets/icons/arrow-primary.svg') no-repeat 52.5% center;
    background-size: 22.5%;

    @media (max-width: 90rem) {
      padding: 2.5rem 2.5rem 0 0;
    }

    &:hover {
      background: $c-primary url('~/assets/icons/arrow-white.svg') no-repeat 52.5% center;
      background-size: 25%;
    }
  }

  // BLOG NEWS
  .section--archive-news-content & {
    top: 0;
    padding: 2.3rem 2.3rem 0 0;
    border-color: $c-primary-extra-light;
    background: transparent url('~/assets/icons/arrow-white.svg') no-repeat 52.5% center;
    background-size: 22.5%;

    &:hover {
      background: $c-primary-extra-light url('~/assets/icons/arrow-primary.svg') no-repeat 52.5% center;
      background-size: 25%;
    }
  }

  // SERVICE TABS
  .service.type-service &,
  .case.type-case & {
    top: 1.5rem;
    border: 0;
    background: transparent url('~/assets/icons/arrow-white.svg') no-repeat 52.5% center;
    background-size: 20%;

    &.slick-disabled {
      opacity: .25;
    }
  }
}

/* SLIDER DOTS
  ----------------------------- */
.slick-slider .slick-dots {
  position: absolute;
  margin: 0;
  padding: 0;
  bottom: -2rem;
  left: 50%;
  transform: translateX(-50%);

  li {
    display: inline-block;
    margin: 0 .3rem;

    button {
      border: 0;
      padding: 0;
      appearance: none;
      background: transparent;
      font-size: 0;
      cursor: pointer;
      
      &:focus {
        outline: none;
      }

      &:before {
        content: '';
        display: inline-block;
        @extend .circle;
        height: .8rem;
        width: .8rem;
        border: 1px solid #000;
      }
    }

    &.slick-active {
      button:before {
        background: #000;
      }
    }
  }
}