/* HEADER MAIN
  ----------------------------- */
.header--main {
  z-index: 999;
  top: 0;
  height: #{$header-height};

  @media #{$breakpoint-md-mx} {
    height: #{$header-height-md};
  }

  body:not(.home) & {
    border-bottom: 1px solid #{$c-primary-light};
  }
  
  .admin-bar & {
    top: 2rem;

    @media #{$breakpoint-md-mx} {
      top: 2.875rem;
    }
  }

  .header__logo-wrapper {
    transition: opacity .4s .4s ease-out;

    body.home & {
      transform: translateX(-185px);

      @media #{$breakpoint-md-mx} {
        transform: translateX(-110px);
      }

      @media #{$breakpoint-sm-mx} {
        transform: translateX(-110px);
      }
    }

    body.search-open & {
      @media #{$breakpoint-sm-mx} {
        opacity: 0;
        transition: opacity .4s ease-out;
      }
    }
  }

  .header__logos {
    width: 24rem;
    transition: opacity .25s 1s ease-in-out;

    @media #{$breakpoint-md-mx} {
      width: 14.25rem;
    }
  }

  .header__logo {
    padding-right: 1.75rem;

    @media #{$breakpoint-sm-mx} {
      padding-right: 1rem;
    }

    body.home & {
      opacity: 0;
    }
  }

  .header__opened-wrapper {
    body.home & {
      border-bottom: 1px solid $c-primary-light;
    }
  }

  .header__menu-wrapper {
    @media (min-width: 140rem) {
      width: 35%;
    }
  }

  .header__menu-item {
    font-size: $font--14;
    font-weight: 600;
  }

  .header__opened-logo-wrapper {
    width: 44.5%;
    padding-top: 3.5rem;
    padding-bottom: 1.45rem;

    @media #{$breakpoint-xxl-mx} {
      padding-top: 5rem;
    }

    @media #{$breakpoint-md-mx} {
      width: 78.5%;
      padding-bottom: .5rem;
    }

    @media #{$breakpoint-sm-mx} {
      width: 100%;
      padding-bottom: 0;
      padding-top: 3.5rem;
    }

    body:not(.home) & {
      @media (min-width: 140rem) {
        width: 33%;
      }
    }
  }

  .header__banner-brand {
    font-size: $font--14;
    margin-top: -1rem;
  }

  // STICKY
  &.sticky:not(.open) {
    body.home & {
      border-bottom: 1px solid #{$c-primary-light};
    }
  }

  // OPEN
  &.open {
    .header__logos {
      opacity: 0 !important;
      transition: opacity .1s ease-in-out;
    }
  }
}