
/* MIXINS
  ----------------------------- */
@mixin linear-responsive($small-size, $large-size, $property:'font-size', $small-width: 375, $large-width: 1920, $gutter: 16) {
  $mobile-offset: calc(#{$large-width} / #{$small-width} / #{$gutter} * (#{$large-size} - #{$small-size}));
  $variable-width: calc((#{$large-size} - (#{$small-size} - #{$mobile-offset})) * #{$gutter} * 100 / #{$large-width});
  #{$property}: calc((1rem * #{$small-size}) - (1rem * #{$mobile-offset}) + (1vw * #{$variable-width}));
}

/* COMMONS
   ----------------------------- */
ul,
ul li {
  list-style-type: disc;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

h1, h2, h3, h4 {
  font-weight: 600;
}

.wysiwyg {
  line-height: 1.2;
  font-weight: 300;

  &--medium {
    @extend .wysiwyg;
    @include linear-responsive(1.125,1.75); // 28px
    font-weight: 200;

    @media (min-width: 52rem) and (max-width: 80rem) {
      font-size: 1.25rem;
    }
  }

  p {
    padding: 0;
    margin: 0 0 1rem;

    &:last-child {
      margin: 0;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    padding: 0;
    margin: 0 0 1rem;
  }

  strong, b {
    font-weight: 600;
    color: $c-primary;
  }

  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }

  a:not(.button) {
    color: $c-primary;
    text-decoration: underline;
  }

  ul li {
    padding-bottom: .5rem;
  }

  table {
    margin-bottom: 2rem;
    border-collapse: collapse;

    td {
      padding: .5rem .75rem;
      border: 1px solid $c-primary-extra-light;
      vertical-align: top;
    }
  }
}

.uppercase {
  text-transform: uppercase;
}

.serif {
  font-family: $serif;
}

/* TITLE
  ----------------------------- */
.title {
  font-family: $serif;
  font-weight: 400;
  line-height: .9;

  &--h1 {
    @extend .title;
    @include linear-responsive(4,7); // 112px
  }

  &--h2 {
    @extend .title;
    @include linear-responsive(3.25,5.95); // 95px
  }

  &--h3 {
    @extend .title;
    @include linear-responsive(2.25,4); // 65px
    line-height: 1;
  }
}

/* LABEL
  ----------------------------- */
.label--default {
  font-size: $font--14;
  font-weight: 600;
  text-transform: uppercase;
}

/* LINK
  ----------------------------- */
// Button Default
.link--button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 15rem;
  padding: .65rem 1rem;
  border: 1px solid $c-primary-light;
  border-radius: 10rem;
  color: $c-primary-light;
  font-size: $font--14;
  text-transform: uppercase;

  @media #{$breakpoint-sm-mx} {
    font-size: .75rem;
  }

  &:hover {
    text-decoration: none;
    background: $c-primary-light;
    color: #FFF;

    &:after {
      background: url('~/assets/icons/arrow-white.svg') no-repeat center center;
      background-size: contain;
    }
  }

  &:after {
    content: '';
    display: inline-block;
    height: .95rem;
    width: .5rem;
    margin-left: 2.5rem;
    background: url('~/assets/icons/arrow-primary-light.svg') no-repeat center center;
    background-size: contain;
    flex-shrink: 0;
  }

  // Color Primary
  &.color--primary {
    border-color: $c-primary;
    color: $c-primary;

    &:hover {
      background: $c-primary;
      color: #FFF;

      &:after {
        background: url('~/assets/icons/arrow-white.svg') no-repeat center center;
        background-size: contain;
      }
    }

    &:after {
      background: url('~/assets/icons/arrow-primary.svg') no-repeat center center;
      background-size: contain;
    }
  }

  // Color White
  &.color--white {
    border-color: #FFF;
    color: #FFF;

    &:hover {
      background: #FFF;
      color: $c-primary;

      &:after {
        background: url('~/assets/icons/arrow-primary-light.svg') no-repeat center center;
        background-size: contain;
      }
    }

    &:after {
      background: url('~/assets/icons/arrow-white.svg') no-repeat center center;
      background-size: contain;
    }
  }
}

// Button Big
.link--button-big {
  @extend .link--button;
  padding: 1.15rem 2rem;
  font-size: $font--16;
}

// No Arrow
.link--no-arrow {
  @extend .link--button;
  min-width: auto;
  padding: .65rem 1.25rem;
  text-align: center;

  &:after {
    content: none;
  }

  &.bg--primary {
    background: $c-primary;
    color: $c-primary-extra-light;
    border-color: $c-primary;

    &:hover {
      background: $c-primary-light;
      border-color: $c-primary-light;
      color: #FFF;
    }
  }
}

// Arrow
.link--arrow {
  background: url('~/assets/icons/arrow-circle-white.svg') no-repeat center center;
  background-size: contain;

  &:hover {
    background: url('~/assets/icons/arrow-circle-white-full.svg') no-repeat center center;
    background-size: contain;
  }

  &.color--primary {
    background: url('~/assets/icons/arrow-circle-primary.svg') no-repeat center center;
    background-size: contain;
  
    &:hover {
      background: url('~/assets/icons/arrow-circle-primary-full.svg') no-repeat center center;
      background-size: contain;
    }
  }

  &.color--primary-light {
    background: url('~/assets/icons/arrow-circle-primary-light.svg') no-repeat center center;
    background-size: contain;
  
    &:hover {
      background: url('~/assets/icons/arrow-circle-white-full.svg') no-repeat center center;
      background-size: contain;
    }
  }
}