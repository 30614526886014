// Converted Variables
$space-075: 0.75rem !default;  //12px
$space-15: 1.5rem !default;  //24px
$space-25: 2.5rem !default;  //40px
$space-35: 3.5rem !default;  //56px
$space-50: 5rem !default;    //80px
$space-75: 7.5rem !default;    //120px

.p075  { padding:        $space-075 }
.pt075 { padding-top:    $space-075 }
.pr075 { padding-right:  $space-075 }
.pb075 { padding-bottom: $space-075 }
.pl075 { padding-left:   $space-075 }
.py075 { padding-top:    $space-075; padding-bottom: $space-075 }
.px075 { padding-left:   $space-075; padding-right:  $space-075 }

.p15  { padding:        $space-15 }
.pt15 { padding-top:    $space-15 }
.pr15 { padding-right:  $space-15 }
.pb15 { padding-bottom: $space-15 }
.pl15 { padding-left:   $space-15 }
.py15 { padding-top:    $space-15; padding-bottom: $space-15 }
.px15 { padding-left:   $space-15; padding-right:  $space-15 }

.p25  { padding:        $space-25 }
.pt25 { padding-top:    $space-25 }
.pr25 { padding-right:  $space-25 }
.pb25 { padding-bottom: $space-25 }
.pl25 { padding-left:   $space-25 }
.py25 { padding-top:    $space-25; padding-bottom: $space-25 }
.px25 { padding-left:   $space-25; padding-right:  $space-25 }

.p35  { padding:        $space-35 }
.pt35 { padding-top:    $space-35 }
.pr35 { padding-right:  $space-35 }
.pb35 { padding-bottom: $space-35 }
.pl35 { padding-left:   $space-35 }
.py35 { padding-top:    $space-35; padding-bottom: $space-35 }
.px35 { padding-left:   $space-35; padding-right:  $space-35 }

.p50  { padding:        $space-50 }
.pt50 { padding-top:    $space-50 }
.pr50 { padding-right:  $space-50 }
.pb50 { padding-bottom: $space-50 }
.pl50 { padding-left:   $space-50 }
.py50 { padding-top:    $space-50; padding-bottom: $space-50 }
.px50 { padding-left:   $space-50; padding-right:  $space-50 }

.p75  { padding:        $space-75 }
.pt75 { padding-top:    $space-75 }
.pr75 { padding-right:  $space-75 }
.pb75 { padding-bottom: $space-75 }
.pl75 { padding-left:   $space-75 }
.py75 { padding-top:    $space-75; padding-bottom: $space-75 }
.px75 { padding-left:   $space-75; padding-right:  $space-75 }