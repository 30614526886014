/* COMMON
  ----------------------------- */
.section--home-what,
.section--home-how,
.section--home-about {
  height: calc(100vh - #{$header-height});
  
  @media (min-width: 140rem) {
    max-height: 75vh;
  }

  @media (min-width: 52rem) and (orientation: portrait) {
    max-height: 35vh;
  }

  @media #{$breakpoint-md-mx} {
    height: auto;
  }

  .section__content-container,
  .section__image-wrapper {
    border-top: 1px solid $c-primary-light;
    border-bottom: 1px solid $c-primary-light;
    margin: 3.5rem 0;
    height: calc(100% - 7rem);

    @media #{$breakpoint-md-mx} {
      height: auto;
      margin: 0;
      border: 0;
    }
  }

  .section__image-wrapper img {
    @media #{$breakpoint-md-mx} {
      aspect-ratio: 16/9;
    }
  }

  .section__body-wrapper {
    max-width: 51.25rem;
  }
}

.section--home-what,
.section--home-about {
  @media #{$breakpoint-md-mx} {
    flex-direction: column-reverse;
  }

  .section__image-container {
    border-right: 1px solid $c-primary-light;

    @media #{$breakpoint-md-mx} {
      border-right: none;
    }
  }
}

.section--home-what,
.section--home-how,
.section--home-about {
  .section__title {
    @media #{$breakpoint-sm-mx} {
      text-align: left;
    }
  }
}

/* HERO
  ----------------------------- */
.section--home-hero {
  .section__header {
    @media #{$breakpoint-md-mx} {
      padding-top: 12rem;
    }
  }

  .section__logo-wrapper {
    z-index: 999;
    aspect-ratio: 8/3;
  }

  .section__logo {
    transition: opacity .25s 1s ease-in-out;
    transform-origin: bottom left;
    height: auto !important;
    max-width: none !important;
    max-height: none !important;

    body.open & {
      opacity: 0;
      transition: opacity 0s ease-in-out;
    }

    body.search-open & {
      @media #{$breakpoint-sm-mx} {
        opacity: 0;
        transition: opacity .4s ease-out;
      }
    }
  }

  .section__animation-wrapper {
    height: calc(100vh - #{$header-height});

    @media #{$breakpoint-md-mx}, (min-width: 52rem) and (orientation: portrait) {
      height: 50vh;
    }
  }

  .section__body {
    width: 64vw;
    opacity: 0;
    transform: translate(-50%,0);

    @media #{$breakpoint-sm-mx} {
      width: 85vw;
    }
  }
}

/* HOW
  ----------------------------- */
.section--home-how {
  border-top: 1px solid $c-primary-light;

  .section__image-container {
    border-left: 1px solid $c-primary-light;

    @media #{$breakpoint-md-mx} {
      border-left: none;
    }
  }
}

/* LATEST NEWS
  ----------------------------- */
.section--home-latest-news {
  min-height: calc(100vh - #{$header-height});
  
  @media (min-width: 140rem) {
    max-height: 75vh;
  }

  @media #{$breakpoint-md-mx}, (min-width: 52rem) and (orientation: portrait) {
    min-height: auto;
  }

  .section__list {
    border-top: 1px solid $c-primary-extra-light;
    border-bottom: 1px solid $c-primary-extra-light;
    margin-bottom: 5rem;

    @media #{$breakpoint-sm-mx} {
      border-bottom: none;
    }
  }

  .section__items-wrapper {
    border-right: 1px solid $c-primary-extra-light;
    border-left: 1px solid $c-primary-extra-light;

    @media #{$breakpoint-sm-mx} {
      border: none;
      border-bottom: 1px solid $c-primary-extra-light;
    }
  }

  .section__item {
    border-bottom: 1px solid $c-primary-extra-light;
    
    &:not(:first-child) {
      padding-top: 1.25rem;
    }
  }
}

/* LATEST INSIGHTS
  ----------------------------- */
.section--home-insights-publications {
  // min-height: calc(100vh - #{$header-height});

  .section__list {
    border-top: 1px solid $c-primary-light;
    border-bottom: 1px solid $c-primary-light;
    // min-height: 45vh;
    margin-bottom: 5rem;

    @media #{$breakpoint-sm-mx} {
      border-bottom: none;
    }
  }

  .section__item {
    border-left: 1px solid $c-primary-light;

    @media #{$breakpoint-md-mx} {
      border-left: none;
      border-bottom: 1px solid $c-primary-light;
    }

    &:last-child {
      border-right: 1px solid $c-primary-light;

      @media #{$breakpoint-md-mx} {
        border-right: none;
        border-bottom: none;
      }
    }
  }
}