/* CAREERS COMMON
  ----------------------------- */
.section--careers-values,
.section--careers-life,
.section--careers-recruiting {
  border-bottom: 1px solid $c-primary-light;
}

.section--careers-life,
.section--careers-recruiting {
  .section__section-paragraph:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  .section__paragraph-title {
    font-size: $font--14;
    font-weight: 600;
  }
}

/* CAREERS CONTENT
  ----------------------------- */
.section--careers-content {
  .awsm-job-wrap {
    .awsm-lists,
    .awsm-job-item {
      border-color: $c-primary-light;
      background: transparent;
    }

    .awsm-job-listing-item:last-child .awsm-job-item {
      border-bottom: none;
    }

    .awsm-job-post-title a {
      font-size: $font--20;
      font-weight: 500;
    }

    a {
      color: $c-primary;
    }
  }
}

/* CAREERS VALUES
  ----------------------------- */
.section--careers-values {
  .section__header {
    border-bottom: 1px solid $c-primary-light;
  }

  .section__container {
    border-right: 1px solid $c-primary-light;
    border-left: 1px solid $c-primary-light;

    @media #{$breakpoint-md-mx} {
      border: none;
    }
  }
  
  .section__body-wrapper {
    font-size: $font--20;
    border-right: 1px solid $c-primary-light;

    @media #{$breakpoint-md-mx} {
      border-right: none;
      border-bottom: 1px solid $c-primary-light;
    }
  }

  .section__values-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1px;

    @media (max-width: 64rem), (min-width: 52rem) and (orientation: portrait) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media #{$breakpoint-sm-mx} {
      grid-template-columns: 1fr;
    }
  }

  .section__value {
    @media (min-width: 40rem) and (max-width: 64rem), (min-width: 52rem) and (orientation: portrait) {
      &:nth-child(3) {
        background: $c-primary;
        color: $c-primary-extra-light;
      }

      &:nth-child(4) {
        background: $c-primary-extra-light;
        color: $c-primary;
      }
    }
  }

  .section__value-title {
    @include linear-responsive(1.5,2);
    @include linear-responsive(3.25,4.75,'height');
    margin-bottom: 2rem;
  }

  .section__value-description:before {
    content: '';
    @extend .absolute-center-x;
    top: 0;
    width: 3rem;
    border-top: 1px solid $c-primary-light;
  }
}

/* CAREERS LIFE
  ----------------------------- */
.section--careers-life {
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 3.5rem;
    bottom: 3.5rem;
    width: 3.5rem;

    @media #{$breakpoint-md-mx} {
      display: none;
    }
  }

  .section__container {
    min-height: calc(100vh - #{($header-height)});

    @media (min-width: 52rem) and (orientation: portrait) {
      min-height: auto;
    }
  }

  .section__container,
  .section__content-container {
    border-left: 1px solid $c-primary-light;

    @media #{$breakpoint-md-mx} {
      border-left: none;
    }
  }

  .section__image-wrapper,
  .section__content-wrapper,
  &:after {
    border-top: 1px solid $c-primary-light;
    border-bottom: 1px solid $c-primary-light;

    @media #{$breakpoint-md-mx} {
      border: none;
    }
  }

  .section__content-wrapper {
    @media #{$breakpoint-md-mx} {
      border-top: 1px solid $c-primary-light;
    }
  }
}

/* CAREERS RECRUITING
  ----------------------------- */
.section--careers-recruiting {
  min-height: calc(100vh - #{($header-height)} - 12rem);

  @media (min-width: 52rem) and (orientation: portrait) {
    min-height: auto;
  }

  .section__content {
    border-right: 1px solid $c-primary-light;
    border-left: 1px solid $c-primary-light;
  }

  .section__label-wrapper.active .section__arrow {
    transform: rotate(-90deg);
  }

  .section__label {
    @include linear-responsive(1.5,2.25);
    font-weight: 500;
  }

  .section__arrow {
    background: url('~/assets/icons/arrow-circle-primary-light.svg') no-repeat center center;
    background-size: contain;
    transform: rotate(90deg);
    transition: all .3s ease-out;
  }
}

/* CAREERS PROCESS
  ----------------------------- */
.section--careers-process {
  display: none;

  .section__content-pseudo-wrapper:after {
    content: '';
    @extend .cover;
    top: calc(100% - 5rem);
    right: calc(1rem + 1px);
    left: 1px;
    background: linear-gradient(0, #{$c-primary} 0, rgba($c-primary, 0) 100%);
    pointer-events: none;
  }

  .section__content-wrapper {
    border-right: 1px solid $c-primary-light;
    border-left: 1px solid $c-primary-light;
    height: calc(100vh - #{($header-height)} - 12rem);
    max-height: 38rem;
    overflow-y: scroll;

    // @media (min-width: 52rem) and (orientation: portrait) {
    //   min-height: auto;
    // }

    @media #{$breakpoint-md-mx} {
      border-right: none;
      border-left: none;
    }
  
    &::-webkit-scrollbar {
      outline: 1px solid $c-primary-light;
    }
  
    &::-webkit-scrollbar-thumb {
      background: $c-primary-light url('~/assets/icons/arrow-primary-mid-down.svg') no-repeat center calc(100% - .25rem);
      background-size: 75%;
    }
  }

  .section__step-number {
    font-size: $font--20;
  }

  .section__step-body strong {
    color: #FFF;
  }
}

/* CAREERS JOIN
  ----------------------------- */
.section--careers-join {
  @media #{$breakpoint-md-mx} {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: start;
  }

  .section__title {
    flex-shrink: 0;
  }

  .section__description {
    max-width: 35rem;
  }
}