/* COMMON
  ----------------------------- */
.section--contacts-location,
.section--contacts-list {
  height: calc(100vh - #{$header-height});

  @media (min-width: 140rem) {
    max-height: 75vh;
  }

  @media (max-width: 80rem) {
    height: auto;
  }

  &:before,&:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    border-top: 1px solid $c-primary-light;

    @media #{$breakpoint-md-mx} {
      content: none;
    }
  }

  &:before {
    top: 3.5rem;
  }

  &:after {
    bottom: 3.5rem;
  }

  .section__container {
    margin-right: 3.5rem;
    margin-left: 3.5rem;
    border-right: 1px solid $c-primary-light;
    border-left: 1px solid $c-primary-light;

    @media #{$breakpoint-md-mx} {
      margin: 0;
      border: none;
    }
  }
  
  .section__wrapper {
    max-width: 48rem;
    margin-bottom: 3.5rem;
    height: 70%;

    @media (max-width: 95rem) {
      height: 85%;
    }

    @media #{$breakpoint-md-mx} {
      margin-bottom: 0;
      height: auto;
    }
  }

  .section__title {
    padding-bottom: clamp(2rem,6vh,4rem);

    @media #{$breakpoint-md-mx} {
      padding-bottom: 2rem;
    }
  }
}

/* CONTENT
  ----------------------------- */
.section--contacts-content {
  border-top: 1px solid $c-primary-light;
  border-bottom: 1px solid $c-primary-light;
}

/* LOCATION
  ----------------------------- */
.section--contacts-location {
  .section__subtitle {
    border-radius: 10rem;
    font-size: $font--18;
    font-weight: 400;
  }

  .section__links {
    row-gap: .75rem;
  }

  .section__where,
  .section__link {
    text-decoration: underline;
    font-size: $font--18;
    font-weight: 300;
  }
}

/* LIST
  ----------------------------- */
.section--contacts-list {
  border-left: 1px solid $c-primary-light;
  
  .section__links {
    max-width: 32rem;
    width: auto;
    transform: translateY(1.75rem);
  }

  .section__cta {
    pointer-events: none;

    @media #{$breakpoint-md-mx} {
      justify-content: center;
    }
    
    &:after {
      content: none;
    }
  }
}