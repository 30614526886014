/* JOB CONTENT
  ----------------------------- */
.type-awsm_job_openings {
  .job__title-wrapper {
    border-bottom: 1px solid $c-primary-light;
  }

  .job__title {
    @include linear-responsive(2.625, 3.125);
  }

  .job__column-media {
    border-right: 1px solid $c-primary-light;

    @media #{$breakpoint-sm-mx} {
      border-right: none;
      border-bottom: 1px solid $c-primary-light;
    }
  }

  .job__content-wrapper {
    @media (min-width: 52rem) and (max-height: 54rem) {
      min-height: 48rem;
    }
  }
    
  .job__content:after {
    content: '';
    @extend .cover;
    top: calc(100% - 6.5rem);
    right: 1rem;
    pointer-events: none;
    background: linear-gradient(0, #{$c-primary-extra-light} 0, rgba(221, 235, 242, 0) 100%);

    @media #{$breakpoint-md-mx} {
      content: none;
    }
  }

  .job__body-wrapper {
    overflow-y: scroll;

    @media #{$breakpoint-md-mx} {
      overflow: unset;
    }

    &::-webkit-scrollbar {
      outline: 1px solid $c-primary-light;
    }

    &::-webkit-scrollbar-thumb {
      background: $c-primary-light url('~/assets/icons/arrow-primary-extra-light-down.svg') no-repeat center calc(100% - .5rem);
      background-size: 75%;
    }
  }
}

/* JOB FORM
  ----------------------------- */
.awsm-job-form .awsm-job-form-inner {
  background: transparent;
  border: none;
  padding: 2rem 0 0;

  > h2 {
    font-size: $font--32;
    font-family: $serif;
    font-weight: 500;
    margin-bottom: 2.5rem;
  }

  .awsm-job-form-group {
    label:not([for*="policy"]) {
      font-size: $font--14;
      font-weight: 600;
      text-transform: uppercase;
    }

    label[for*="policy"] {
      cursor: pointer;
      font-size: $font--16;
      font-weight: 400;
    }

    input[type="text"],input[type="email"],textarea,
    .awsm-selectric-wrapper .awsm-selectric span.label,
    .awsm-selectric-scroll li {
      color: $c-primary;
      font-size: $font--16;
      font-weight: 400;
    }

    input[type="text"],input[type="email"],input[type="tel"],textarea {
      appearance: none;
      background: #FFF;
      border: 1px solid #FFF;
      border-radius: 0;
      padding: .75rem;

      &:focus-visible {
        outline: none;
        border-color: $c-primary-light;
      }
    }

    .awsm-selectric-wrapper .awsm-selectric {
      box-shadow: none;
      border-color: #FFF;
    }

    .awsm-job-form-error {
      font-size: $font--14;
      font-weight: 500;
      padding-top: .5rem;
    }

    input[type="checkbox"] {
      appearance: none;
      position: relative;
      top: 3px;
      margin-right: .5rem;
      height: 1.125rem;
      width: 1.125rem;
      border-radius: 2rem;
      border: 1px solid $c-primary-light;

      &:checked {
        background: $c-primary-light url('~/assets/icons/checked.svg') no-repeat center center;
        background-size: 50%;
      }
    }

    input[type="submit"] {
      @extend .link--button-big;
      margin-top: 2rem;
      background: transparent;
      cursor: pointer;
    }
  }
}