/* MENU PRIMARY
  ----------------------------- */
.menu--primary {
  display: grid;
  // grid-template-columns: repeat(3,auto);
  grid-template-columns: .75fr 1.35fr .75fr;
  row-gap: 1rem;

  @media #{$breakpoint-sm-mx} {
    display: flex;
    flex-direction: column;
    row-gap: .25rem;
  }

  li {
    @extend .list-reset;
    position: relative;
    display: inline-block;
    padding: 0 .5rem;
    text-align: right;

    @media #{$breakpoint-sm-mx} {
      padding: 0;

      &.menu-item-2817,
      &.menu-item-2822 {
        order: -1;
      }

      &.menu-item-3056 {
        order: 2;
      }
    }

    &.current-menu-item a,
    &:hover a {
      font-weight: 500;
    }

    a {
      display: inline-block;
      text-decoration: none;
      text-transform: uppercase;
      font-size: $font--18;
      font-weight: 200;
      color: #FFF;

      @media #{$breakpoint-sm-mx} {
        font-size: $font--16;
      }
    }
  }
}

/* MENU SOCIAL + SECONDARY
  ----------------------------- */
.menu--social,
.menu--secondary {
  @media #{$breakpoint-md-mx} {
    justify-content: center;
  }

  .menu-item {
    list-style: none;

    a {
      font-size: .75rem;
      font-weight: 600;
      text-transform: uppercase;
      color: #FFF;
    }
  }
}

.menu--social .menu-item {
  padding: 0 .35rem;
}