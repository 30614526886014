.button {
  cursor: pointer;
  position: relative;
  display: inline-block;
  padding: .8rem 2.5rem .75rem;
  border-radius: .2rem;
  transition: all .3s ease-out;

  @media #{$breakpoint-md-mx} {
    padding: .8rem 1.5rem .75rem;
  }

  &:hover {
    text-decoration: none;
  }
}