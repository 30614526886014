/* COMMON
  ----------------------------- */
.section--archive-insights-content,
.section--archive-news-content {
  .section__body {
    @include linear-responsive(1.125, 1.5);
  }

  .section__filters-parent-wrapper,
  .section__filters-children-wrapper,
  .section__filters-categories-wrapper {
    .facetwp-type-reset .facetwp-reset,
    .facetwp-type-checkboxes .facetwp-checkbox {
      @extend .link--no-arrow;
      font-size: $font--16;
      text-transform: capitalize;
    }

    .facetwp-type-reset .facetwp-reset {
      cursor: pointer;

      &:not(.bg--primary) {
        background: transparent;

        &:hover {
          background: $c-primary-light;
        }
      }
    }

    .facetwp-type-checkboxes {
      margin-left: .25rem;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: end;
      gap: .5rem .25rem;

      .facetwp-checkbox {
        background: transparent;
        margin: 0;

        &.checked {
          @extend .bg--primary;
        }
      }

      .facetwp-counter {
        display: none;
      }
    }
  }
}

/* ARCHIVE INSIGHTS & PUBLICATIONS
  ----------------------------- */
.section--archive-insights-content {
  .section__header,
  .section__container,
  .section__row {
    border-bottom: 1px solid $c-primary-light;
  }

  .section__body,
  .section__filters-wrapper,
  .section__item {
    border-left: 1px solid $c-primary-light;

    // @media #{$breakpoint-md-mx} {
    //   border-left: 0;
    //   border-bottom: 1px solid $c-primary-light;
    // }
  }

  .section__body,
  .section__filters-wrapper {
    @media #{$breakpoint-sm-mx} {
      border-left: 0;
      border-bottom: 1px solid $c-primary-light;
    }
  }

  .section__item {
    @media #{$breakpoint-md-mx} {
      border-left: 0;
      border-bottom: 1px solid $c-primary-light;
    }
  }

  .section__filters-wrapper,
  .section__item:last-child {
    border-right: 1px solid $c-primary-light;

    @media #{$breakpoint-md-mx} {
      border-right: 0;
      border-bottom: 0;
    }
  }

  .section__search-and-pager {
    @media #{$breakpoint-md-mx} {
      flex-direction: column;
      align-items: start;
    }
  }
}

/* ARCHIVE NEWS & EVENTS
  ----------------------------- */
.section--archive-news-content {
  .section__container {
    border-top: 1px solid $c-primary-extra-light;
    border-bottom: 1px solid $c-primary-extra-light;

    @media (min-width: 52rem) and (max-height: 54rem) {
      min-height: 30rem;
    }

    @media (min-width: 52rem) and (orientation: portrait) {
      max-height: 40vh;
    }
  }

  .section__wrapper {
    border-right: 1px solid $c-primary-extra-light;

    @media #{$breakpoint-md-mx} {
      border-right: 0;
      border-bottom: 1px solid $c-primary-extra-light;
    }
  }

  .section__body {
    max-width: 32rem;
  }

  .section__interactions {
    max-width: 24rem;
  }

  .section__filters-categories-wrapper {
    .facetwp-type-reset .facetwp-reset {
      &.bg--primary {
        background: $c-primary-extra-light;
        border-color: $c-primary-extra-light;
        color: $c-primary-mid;
      }

      &:not(.bg--primary) {
        border-color: $c-primary-extra-light;
        color: $c-primary-extra-light;
  
        &:hover {
          border-color: $c-primary-light;
        }
      }
    }

    .facetwp-type-checkboxes {
      margin-left: .5rem;
      column-gap: .5rem;

      .facetwp-checkbox {
        border-color: $c-primary-extra-light;
        color: $c-primary-extra-light;

        &:hover {
          border-color: $c-primary-light;
        }
        
        &.checked {
          border-color: $c-primary-extra-light;
          background: $c-primary-extra-light;
          color: $c-primary-mid;
        }
      }
    }
  }

  .section__search {
    .facetwp-type-search,
    .facetwp-input-wrap,
    .facetwp-search {
      width: 100%;
    }
  }

  .section__years-slider {
    padding: 0 2.75rem;
  }

  .section__year-wrapper {
    padding: 0 .25rem;
  }

  .section__year {
    justify-content: center;
    background: transparent;
    color: $c-primary-extra-light;
    border-color: $c-primary-extra-light;

    &:hover {
      background: $c-primary-extra-light;
      color: $c-primary-mid;
    }

    &.active {
      @extend .bg--primary;
    }
  }

  .section__archive-wrapper {
    @media #{$breakpoint-md-mx} {
      height: 80vh;
    }

    &:after {
      content: '';
      @extend .cover;
      top: 75%;
      left: 1rem;
      background: linear-gradient(0, #{$c-primary-mid} 0, rgba($c-primary-mid, 0) 100%);
      pointer-events: none;

      @media #{$breakpoint-md-mx} {
        top: 85%;
      }
    }
  }

  .section__archive {
    overflow-y: scroll;
    direction: rtl;

    // @media #{$breakpoint-md-mx} {
    //   direction: ltr;
    // }

    &::-webkit-scrollbar {
      outline: 1px solid $c-primary-extra-light;
    }

    &::-webkit-scrollbar-thumb {
      background: $c-primary-extra-light url('~/assets/icons/arrow-primary-mid-down.svg') no-repeat center calc(100% - .25rem);
      background-size: 75%;
    }
  }

  .section__list {
    direction: ltr;
    padding-bottom: 7.5rem;

    @media #{$breakpoint-md-mx} {
      padding-bottom: 5rem;
    }
  }

  .section__item {
    border-bottom: 1px solid $c-primary-extra-light;

    &:not(:first-child) {
      padding-top: 1.5rem;
    }
  }
}