/* FONTS
   ----------------------------- */
$sans-serif: 'franklin-gothic-atf', sans-serif;
$serif: 'than', serif;

/* SPACINGS
   ----------------------------- */
$main-font-size: 1rem;
$font--48: 3rem;
$font--32: 2rem;
$font--30: 1.875rem;
$font--24: 1.5rem; 
$font--20: 1.25rem;
$font--18: 1.125rem;
$font--16: $main-font-size;
$font--14: .875rem;

$col--1: 8.33%;

$header-height: 8.5rem;
$header-height-md: 5.125rem;

/* COLORS
   ----------------------------- */
$c-primary: #005790;
$c-primary-mid: #2C82B8;
$c-primary-light: #58AEE0;
$c-primary-extra-light: #DDEBF2;
$c-primary-dark: #001431;


/* MEDIA QUERIES
   ----------------------------- */
$breakpoint-sm-mx: '(max-width: 40em)'; // 640px
$breakpoint-md-mx: '(max-width: 52em)'; // 832px
$breakpoint-lg-mx: '(max-width: 64em)'; // 1024px
$breakpoint-xl-mx: '(max-width: 78em)'; // 1248px
$breakpoint-xxl-mx: '(max-width: 90em)'; // 1440px
