.service.type-service,
.case.type-case {

  // SERVICE CATEGORY
  .service__timeline-wrapper,
  .service__categories-tabs,
  .service__content-wrapper {
    border-top: 1px solid $c-primary-light;
  }

  .service__header {
    @media #{$breakpoint-md-mx} {
      flex-direction: column;
      align-items: start;
    }
  }

  .service__backlink-wrapper {
    flex-shrink: 0;
  }

  .service__backlink {
    .service__backlink-arrow {
      background: url('~/assets/icons/arrow-white.svg') no-repeat center center;
      background-size: contain;
    }

    &:hover  {
      color: $c-primary;

      .service__backlink-arrow {
        background: url('~/assets/icons/arrow-primary.svg') no-repeat center center;
        background-size: contain;
      }
    }

    &:after {
      content: none;
    }
  }

  .service__timeline-title {
    @include linear-responsive(1.5,2);
    font-weight: 500;
  }

  .service__timeline-btn {
    @media #{$breakpoint-md-mx} {
      height: 2.25rem;
      width: 2.25rem;
      min-width: auto;
      font-size: 0;
      padding: 0;
      justify-content: center;

      &:after {
        position: relative;
        top: 1px;
        margin: 0;
      }
    }

    &:after {
      transform: rotate(90deg);
      transition: all .3s ease-out;
    }

    &.active:after {
      transform: rotate(-90deg);
    }
  }

  .service__timeline {
    max-height: 0;
    transition: max-height .5s ease-in-out, box-shadow .25s .25s ease-in-out;

    &.open {
      max-height: 40rem;
      box-shadow: 0 -1px $c-primary-light;
      transition: max-height .5s ease-in-out;

      @media (min-width: 140rem) {
        max-height: 60rem;
      }
    }
  }

  // TABS
  .service__subcategories-main:not(.active) {
    display: none;
  }

  .service__categories-main:not(.active) {
    @media #{$breakpoint-md-mx}, (min-width: 52rem) and (orientation: portrait) {
      display: none;
    }
  }

  .service__categories-mobile-pretab,
  .service__subcategories-mobile-pretab {
    @media (min-width: 52rem) and (orientation: landscape) {
      display: none;
    }
  }

  .service__categories-tabs,
  .service__subcategories-tabs {
    @media #{$breakpoint-md-mx},(min-width: 52rem) and (orientation: portrait) {
      flex-direction: column;
      align-items: start;
      background: $c-primary-light;
      padding: .75rem 0;
    }
  }

  .service__subcategories-tabs {
    border-top: 1px solid #FFF;
    // background-image: linear-gradient(#FFF 1px, transparent 1px);
    // background-size: 100% 64px;

    @media #{$breakpoint-md-mx}, (min-width: 52rem) and (orientation: portrait) {
      border-top: none;
      // background-image: none;
      // border-top: 1px solid #FFF;
    }

    // &:has(> :nth-child(7)),
    &:has(> :last-child:nth-child(2)) {
      justify-content: start;
    }

    &:has(> :last-child:nth-child(2)) {
      .service__subcategory-title {
        width: 20%;

        @media #{$breakpoint-md-mx}, (min-width: 52rem) and (orientation: portrait) {
          width: 100%;
        }
      }
    }
  }

  .service__subcategories-arrow {
    background: url('~/assets/icons/arrow-circle-white.svg') no-repeat center center;
    background-size: contain;
    transform: rotate(180deg);
  }

  .service__subcategory-title {
    font-size: $font--14;

    @media (min-width: 52rem) and (orientation: portrait), (min-width: 52rem) and (max-width: 85rem) {
      font-size: .75rem;
    }
  }

  .service__category-title,
  .service__subcategory-title,
  .service__case-link {
    @media #{$breakpoint-md-mx}, (min-width: 52rem) and (orientation: portrait) {
      font-size: $font--14;
      padding: .35rem 2rem;
    }

    &.active {
      font-weight: 600;
      background: $c-primary-light;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .service__category-title,
  .service__case-link {
    @include linear-responsive(.625,1);
    @include linear-responsive(.35,2.5,'padding-right');
    @include linear-responsive(.35,2.5,'padding-left');
    flex: 1 0 auto;

    @media (min-width: 52rem) and (orientation: portrait) {
      flex-shrink: 1;
    }

    @media #{$breakpoint-md-mx}, (min-width: 52rem) and (orientation: portrait) {
      font-size: $font--14;
      padding: .35rem 2rem;
    }
  }

  // SERVICE + CASE CONTENT
  .service-wrapper-reverse {
    flex-direction: row-reverse;
  }

  .case__nav-wrapper {
    border-bottom: 1px solid $c-primary-light;
  }

  .case__nav-link {
    min-width: auto;

    &:after {
      margin-left: 1.25rem;
    }

    &.case__nav-link--prev {
      flex-direction: row-reverse;

      &:after {
        transform: rotate(180deg);
        margin: 0 1.25rem 0 0;
      }
    }
  }

  .service__column-media,
  .case__column-media {
    row-gap: 3.5rem;

    &.has-image {
      border-right: 1px solid $c-primary-light;
    }
  }

  .service__video-wrapper,
  .case__video-wrapper {
    max-width: 30rem;
  }

  @for $i from 1 through 5 {
    $scale: 1.15 + ($i - 1) * 0.5;
    .service__video-wrapper:nth-child(5n+#{$i}) .service__video,
    .case__video-wrapper:nth-child(5n+#{$i}) .case__video {
      transform: translateX(-50%) scale(#{$scale});
    }
  }

  .service__paragraph-title,
  .case__paragraph-title {
    font-size: $font--14;
    font-weight: 600;
  }
}

// COVER IMAGE
.service.type-service:has(.service__categories-tabs .service__category-title.active.current-term) .service__cover-image,
.case.type-case:has(.service__categories-tabs .service__case-link.active) .service__cover-image {
  display: none;
}