.color--white {
  color: #FFF;
}

.color--primary {
  color: $c-primary;
}

.color--primary-mid {
  color: $c-primary-mid;
}

.color--primary-dark {
  color: $c-primary-dark;
}

.color--primary-light {
  color: $c-primary-light;
}

.color--primary-extra-light {
  color: $c-primary-extra-light;
}

.bg--white {
  background-color: #FFF;
}

.bg--primary {
  background-color: $c-primary;
}

.bg--primary-mid {
  background-color: $c-primary-mid;
}

.bg--primary-dark {
  background-color: $c-primary-dark;
}

.bg--primary-light {
  background-color: $c-primary-light;
}

.bg--primary-extra-light {
  background-color: $c-primary-extra-light;
}